@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

html {
  font-size: 15px;
}

body {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #fff;
  background-color: rgb(25, 44, 80);
  font-size: 14px;
}

.btn {
  font-size: 13px;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

.text-gray {
  color: #888;
}

.text-yellow {
  color: rgb(239, 212, 141);
}

/* sidebar */
.sidebar {
  width: 260px;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 999;
  background: #182d50;
  border-right: 1px solid #646464;
  transition: all 0.7s ease-in-out 0s;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-shrink: 0;
}

.sidebar-logo {
  padding: 17px;
  width: auto;
  position: relative;
  transition: all 0.6s ease-in-out 0s;
}

.logo-title {
  color: rgb(239, 212, 141);
}

.sidebar-line {
  border-bottom: 1px solid #303030;
}

.sidebar-menu {
  position: relative;
  height: calc(-100px + 100vh);
}

.sidebar-menu-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-menu-nav li {
  border-bottom: 1px solid #303030;
  padding: 15px;
}

.sidebar-menu-nav li a.active {
  font-size: 18px;
  color: rgb(239, 212, 141);

}

.sidebar-menu-nav li a {
  font-size: 18px;
  color: #fff;

}

.sidebar-bottom {
  flex: 0 0 auto;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.social-list li {
  display: inline-flex;
  margin-right: 6px;
}

.social-list li a {
  width: 40px;
  height: 40px;
  border: 1px solid rgb(239, 212, 141);
  border-radius: 100%;
  background: #182d50;
  line-height: 40px;
  text-align: center;
  color: rgb(239, 212, 141);
}

@media screen and (max-width: 1024px) {
  .sidebar-menu {
    height: calc(-160px + 100vh);
  }
}

/* main content */
.content {
  /* padding: 0 20px; */
  min-height: 100vh;
  margin-left: 250px;
  transition: all 0.7s ease-in-out 0s;
  position: relative;
}

.main-content {
  padding: 0 20px;
}

.header {
  padding: 20px;
  border-bottom: 1px solid #303030;
}

.btn-primary {
  background: #f42929;
  color: #fff;
  border: none;
  font-size: 16px;
}

.btn-primary:hover {
  background: #f42929;
  color: #000;
}

.btn-secondary {
  background: #38cedc;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0 0 11px 0px #38cedc;
}

.btn-secondary:hover {
  background: #38cedc;
  color: #fff;
}

.btn-info {
  background: #38cedc;
  border-color: #38cedc;
}

.btn-outline-info {
  border-color: #38cedc;
}

.box-card {
  margin-bottom: 1.875rem;
  background-color: #0C150C;
  transition: all .5s ease-in-out;
  position: relative;
  border: 1px solid rgb(239, 212, 141);
  border-radius: 10px;
  box-shadow: none;
}

.box-card-body {
  padding: 1.25rem;
}

.icon-box {
  border: 1px solid rgb(239, 212, 141);
  border-radius: 10px;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 40px;
  background: #FFBF00;
}

.box-card-body h6 {
  font-weight: 500;
  color: #000;
}

.box-card-body p {
  font-weight: 400;
  color: #000;
  font-size: 17px;
}

.bg-green {
  background: #11de25;
}

.bg-red {
  background: #f52b2b;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.5em !important;
  font-size: 1.8rem !important;
}

.text-small {
  font-size: 13px;
}

.card {
  background: rgb(36, 63, 116);
  /* border: 0.5px solid #f0d48d; */
}

.nav-tabs .nav-link {
  width: 50%;
  font-size: 18px;
  font-weight: 500;
}

.nav-tabs {
  border-bottom: 1px solid rgb(239, 212, 141);
}

.nav-tabs .nav-link {
  color: #f5f5f5;
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: rgb(239, 212, 141);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: none;
}

.form-control {
  background: #1d1d1d;
  border-color: #5c5c5c;
  color: #fff;
}

.form-control::-webkit-input-placeholder {
  color: #5c5c5c;
}

.form-control:-moz-placeholder {
  color: #5c5c5c;
}

/* .input-group .form-control,
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 0.375rem !important;
} */

.btn-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.btn-list li {
  margin-right: 10px;
}

.btn-list li:last-child {
  margin-right: 0;
}

.round-badge {
  width: 20px;
  height: 20px;
  background: #1fcaad;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round-badge-yellow {
  width: 20px;
  height: 20px;
  background: rgb(239, 212, 141);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-gray {
  width: 30px;
  height: 30px;
  background: #312f2f;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-gray {
  background: #0C150C;
}

.table>:not(caption)>*>* {
  background: #243f74;
  color: #fff;
  border-bottom-width: 0;
  line-height: 24px;
}

.toggle-sidebar-btn {
  cursor: pointer;
}

.toggle-sidebar .sidebar {
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  width: 80px;
}

.mini-logo {
  display: none;
}

.toggle-sidebar .mini-logo {
  display: block;
}

.toggle-sidebar .big-logo {
  display: none;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.toggle-sidebar .sidebar-logo {
  padding: 17px 10px;
}

.toggle-sidebar .sidebar-menu-nav li {
  text-align: center;
}

.toggle-sidebar .sidebar-menu-nav li a span {
  display: none;
}

.toggle-sidebar .content {
  margin-left: 80px;
}

.desk-none {
  display: none;
}

.ref-history .card-header {
  background: #243f74;
  border: 0.5px solid #243f74;
}

@media (max-width: 1023px) {
  .desk-none {
    display: block;
  }

  .sidebar {
    display: inline-block;
    z-index: 99;
    left: -264px;
    top: 0px;
  }

  .toggle-sidebar .sidebar {
    left: 0px;
    width: 250px;
  }

  .content {
    margin-left: 0px;
    height: 100% !important;
  }

  .toggle-sidebar .sidebar-menu-nav li {
    text-align: left;
  }

  .toggle-sidebar .sidebar-menu-nav li a span {
    display: inline;
  }
}

@media (max-width:767px) {
  .icon-box {
    margin-bottom: 10px;
  }
}


.nav.nav-tabs button {
  height: 55px;
}

.card-header {
  padding: 15px;
}

input,
.form-control {
  color: #fff;
}

.form-control:focus {
  color: #fff;
  background-color: transparent;
}

h6 {
  margin-bottom: 0;
}

.h5,
h5 {
  font-size: 1.05rem;
}

.input-group-text {
  border-radius: 5px;
  color: #fff;
  border: none;
  background: #1d1d1d;
}

.pst-input {
  border-radius: 0 !important;
}

.btn-full {
  width: 80%;
}


.ref-main {
  /* border: 0.5px solid #f0d48d; */
  padding: 20px;
  background: transparent;
}

.ref-input {
  width: 60% !important;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  text-align: center;
  background: #182d50;
  height: 45px;
}


.level-span {
  font-size: 16px;
}

:root {
  --hue: 223;
  --trans-dur: 0.3s;
  --trans-timing: cubic-bezier(0.65, 0, 0.35, 1);
}

.login__input-textbox {
  border-radius: 0.75em;
  outline: transparent;
  padding: 14px;
  width: 100%;
  transition: box-shadow var(--trans-dur), color var(--trans-dur);
}

.btn-warning-info {
  border-color: rgb(239, 212, 141);
  color: #fff;
}

.btn-warning-info:hover {
  background: rgb(25 144 26) !important;
  color: #fff;
}


.input-group-text {
  cursor: pointer;
}

.add-liquidity {
  background-color: #282828;
  border-radius: 11px;
  padding: 1.6px;
}

.add-liquidity .content-stake {
  margin: 15px;
}

.add-liquidity .content-stake .labels {
  padding: 0 24px;
}

.add-liquidity .content-stake .labels h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-align: center;
}


.add-liquidity .content-stake .labels1 {
  padding: 0 24px;
}

.add-liquidity .content-stake .labels1 input {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  text-align: center;
  width: 300px;
  background: #313131;
  height: 45px;
}

.icon-img {
  height: 40px;
  padding: 0 10px;
}

.text-xs {
  font-size: 10px;
}

.bg-dark-1 {
  border: 1px solid #fff;
  border-radius: 10px;
  background: #182d50;
}

.w-45 {
  width: 49%;
}

.btn-tab-bar {
  height: 55px;
  width: 48%;
  color: #fff;
  border-radius: 20px;
  border: 0.5px solid #182d50;
  background: #182d50;
  font-size: 14px;
}

.btn-ref-tab {
  color: rgb(244, 41, 41);
}

.btn-tab-bar.active,
.btn-tab-bar:hover {
  background: #ffc107;
  color: #000;
}

.border-primary {
  border-color: #5c5c5c !important;
}

.table-unstake {
  font-size: 11px;
}

.theme-btn1 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0px 7px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(118.27deg, #136d2a 0%, #136d2a 100%);
  box-shadow: 0px 15px 30px #f0f0f02b;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
}

table {
  white-space: nowrap;
  text-align: center;
}

.theme-btn1:disabled,
.btn:disabled {
  color: #fff;
  background: #555555;
  border-color: #474747;
  cursor: not-allowed;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.btn-warning-info,
.btn-outline-warning {
  border: 0.5px solid #00B2B2;
  color: #fff;
  background: #192C50;
}

.btn-primary,
.btn-tab-bar.active,
.btn-warning,
.bg-warning {
  background: rgb(239, 212, 141) !important;
  border: 0.5px solid #5c5c5c;
  color: #000;
}

.btn-primary:hover,
.btn-tab-bar:hover,
.btn-warning:hover,
.btn-warning-info:hover,
.btn-outline-warning:hover {
  background: transparent !important;
  border: 0.5px solid rgb(239, 212, 141);
  color: rgb(239, 212, 141);
}


.rangeslider-horizontal {
  padding-left: 0px !important;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: rgb(239, 212, 141) !important;
}

.btn-warning {
  border: 1px solid #fff;
}

.text-website {
  color: rgb(239, 212, 141);
}

.ref-badge {
  padding: 5px;
  font-size: 10px;
}

/* .bg-ref-button:hover{
  background: transparent;
} */


.back1-top {
  background-color: rgb(36, 63, 116);
  box-shadow: none;
  background-image: url('./img/back1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.back2-top {
  background-color: rgb(36, 63, 116);
  box-shadow: none;
  background-image: url('./img/back2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.back3-top {
  background-color: rgb(36, 63, 116);
  box-shadow: none;
  background-image: url('./img/back3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.MuiButton-primaryPrimary{
  color: #000 !important;
}

.input-around{
  padding: 15px;
  background: rgb(24, 45, 80);
  border-radius: 10px;
}

.table th{
  background: #192C50;
}




@media only screen and (max-width: 600px) {
  .btn {
    font-size: 9px;
  }

  .btn-tab-bar {
    font-size: 13px;
  }

  .add-liquidity .content-stake .labels {
    padding: 0 18px;
  }

  .add-liquidity .content-stake .labels1 {
    padding: 0px 0px;
  }

  .add-liquidity .content-stake .labels1 input {
    width: 200px;
  }

  .box-card-body h6 {
    font-size: 13px;
  }

  .box-card-body p {
    font-size: 13px;
  }

  .wallet-card h6,
  .wallet-card h5 {
    font-size: 13px;
  }

  .text-small {
    font-size: 9px;
  }

  .mob-break{
    display: block;
  }
}

.btn-wccoin:hover{
  background: rgb(239, 212, 141) !important;
  color:  #000;
}